import * as React from 'react';
import Logo from './logo_1_valge_new.svg';

export default () => {
  return (
      <img
          onClick={() => location.href = 'https://web.peatus.ee'}
          className="logo"
          id={'logo'}
          src={Logo}
          style={{ cursor: 'pointer' }}
      />
  );
};
