import React, { useRef, useEffect, useState, RefObject } from "react";
import ResizeObserver from "resize-observer-polyfill";

interface DynamicTextFitterProps {
  text: string;
  maxCharacters: number;
  parentRef: RefObject<HTMLDivElement>;
}

const DynamicTextFitter: React.FC<DynamicTextFitterProps> = ({
                                                               text,
                                                               maxCharacters,
                                                               parentRef,
                                                             }) => {
  const containerRef = useRef<HTMLDivElement>(null); // Visible container
  const hiddenTestRef = useRef<HTMLSpanElement>(null); // Hidden element for measurements
  const [fontSize, setFontSize] = useState<number>(20); // Initial font size
  const [containerWidth, setContainerWidth] = useState<number>(0); // Track container width

  // Update container width using ResizeObserver
  useEffect(() => {
    const updateContainerWidth = () => {
      if (parentRef.current) {
        console.log(parentRef.current.offsetWidth);
        setContainerWidth(parentRef.current.offsetWidth);
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      updateContainerWidth();
    });

    if (parentRef.current) {
      resizeObserver.observe(parentRef.current);
    }

    updateContainerWidth(); // Initial width update

    return () => resizeObserver.disconnect(); // Cleanup observer
  }, [parentRef]);

  // Adjust font size dynamically
  useEffect(() => {
    const adjustFontSize = () => {
      if (!containerRef.current || !hiddenTestRef.current || containerWidth === 0)
        return;

      const testElement = hiddenTestRef.current;
      testElement.style.visibility = "hidden";
      testElement.style.whiteSpace = "nowrap";
      testElement.style.fontFamily = window.getComputedStyle(containerRef.current).fontFamily;
      testElement.textContent = text;

      let minFontSize = 10;
      let maxFontSize = 95;
      let adjustedFontSize = minFontSize;

      while (minFontSize <= maxFontSize) {
        const midFontSize = Math.floor((minFontSize + maxFontSize) / 2);
        testElement.style.fontSize = `${midFontSize}px`;

        const measuredWidth = testElement.offsetWidth;

        if (measuredWidth > containerWidth) {
          maxFontSize = midFontSize - 1;
        } else {
          adjustedFontSize = midFontSize;
          minFontSize = midFontSize + 1;
        }
      }

      setFontSize(adjustedFontSize); // Update font size state
    };

    adjustFontSize();
  }, [text, containerWidth]); // Recalculate when text or container width changes

  return (
    <div>
      {/* Hidden span for background calculations */}
      <span
        ref={hiddenTestRef}
        style={{
          position: "absolute",
          visibility: "hidden",
          whiteSpace: "nowrap",
        }}
      />
      {/* Visible container */}
      <div
        ref={containerRef}
        style={{
          fontSize: `${fontSize}px`,
          textAlign: "center",
          width: "100%",
        }}
      >
        {text}
      </div>
    </div>
  );
};

export default DynamicTextFitter;
