import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import cx from 'classnames';
import {ConfigContext} from "../contexts";

interface IProps {
  preview: boolean;
  text: string;
  id: string;
}

const getAnimationWidth = (elementRef) => {
  if (!elementRef.current) {
    return 0;
  }
  const gridHeader = document.getElementsByClassName(`grid-header destination`)[0];
  if (!gridHeader) {
    return 0;
  }
  return elementRef.current.clientWidth - gridHeader.clientWidth + 5; //to let text end scroll out of shadow
};

const RouteLongNameScrolling: FC<IProps> = ({
                                              preview,
                                              text,
                                              id,
                                            }) => {
  const [animationWidth, setAnimationWidth] = useState(0);
  const [speed, setSpeed] = useState(0);
  const [update, setUpdate] = useState(false);
  const config = useContext(ConfigContext);
  const scrollSpeed = config.routeNameScrollSpeed;

  const elementRef = useRef<HTMLSpanElement>(null);

  const updateAnimation = () => {
    const width = getAnimationWidth(elementRef);
    setAnimationWidth(width);
    setSpeed(width / scrollSpeed);
    setUpdate(true);
  };
  // ---------------------------------
  useEffect(() => {
    const handleResize = () => {
      requestAnimationFrame(() => {
        updateAnimation();
        setTimeout(() => setUpdate(false), 100);
      });
    };

    window.addEventListener('resize', handleResize);
    updateAnimation(); // Initial call

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const style = {
    '--animationWidth': `${Number(-1 * animationWidth).toFixed(0)}px`,
    '--speed': `${Number(speed).toFixed(0)}s`,
  } as React.CSSProperties;
  return (
    <div style={style} className={cx('grid-row', 'route-name', 'horizontal')}>
      <div className={cx('grid-cols', 'alert-row')} style={{padding: 0}}>
        {/*<div>*/}
        <div
          className={cx('alert-text', {
            animated: !update,
          })}
          onAnimationIteration={() => {
            updateAnimation();
            requestAnimationFrame(() => setUpdate(false));
          }}
        >
          <span ref={elementRef} key={`longName-${id}`} className={`single-alert`}>
            {text}
          </span>
        </div>
      </div>
    </div>
  );
};

export default RouteLongNameScrolling;
