import React from 'react';
import {useTranslation} from 'react-i18next';
import Logo from './logo/Logo';
import {Link} from "react-router-dom";

const Banner = () => {
  const [t, i18n] = useTranslation();

  const changeLanguage = (i18n, lang) => {
    i18n.changeLanguage(lang);
    if (lang !== localStorage.getItem('lang')) {
      localStorage.setItem('lang', lang);
    }
  };

  const languageCodes = ['et', 'en'];//, 'fi'];

  const languageElements = () => {
    const retValue = [];
    languageCodes.forEach(language => {
      retValue.push(
        <Link
          key={language}
          className="lang-select"
          onClick={() => changeLanguage(i18n, language)}
          to={window.location.pathname}
          aria-label={t('changeLanguage', {
            language: t(`language-name-${language}`),
          })}
        >
          {language}
        </Link>,
      );
    });
    return retValue;
  };

  return (
    <div className="main-banner">
      <Logo isLandscape />
      <section
        id="languages"
        aria-label={t('languageSelection')}
      >
        {languageElements()}
      </section>
    </div>
  );
};
export default Banner;
