import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: 'et',
  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
  supportedLngs: ['et', 'en'],//, 'fi'],
  lng: window.localStorage.getItem('lang') || 'et',

  react: {
    nsMode: 'default',
    useSuspense: true,
  },

  resources: {
    en: {
      translation: {
        'add-at-least-one-stop': 'Add at least one stop',
        'add-to-own-displays': 'Add to my displays',
        all: 'All',
        arriveTerminal: 'Arrives / Terminus',
        autosuggestPlaceHolder: 'Name or number of the stop',
        breadCrumbsCreate: 'Create a stop display',
        breadCrumbsFrontPage: 'Frontpage',
        breadCrumbsHelp: 'Help',
        breadCrumbsModify: 'Edit the stop display',
        breadCrumbsOwnMonitors: 'My stop displays',
        breadCrumbsSite: 'Journey Planner',
        cancel: 'Cancel',
        cancelled: 'Cancelled',
        changeLanguage: 'Change language to {{language}}',
        chooseOne: 'Select at least one',
        close: 'Close',
        closedStop: 'Stop closed',
        closedStopWithRange:
          'Stop {{name}} {{code}} closed between {{startTime}} and {{endTime}}',
        'confirm-choice': 'Confirm choice',
        continue: 'Continue',
        copy: 'Copy link',
        createViewTitle: 'Creation of a new view',
        delete: 'Delete',
        'delete-confirmation': 'Are you sure you want to delete this monitor',
        deleteRenamings: 'Clear text changes',
        'delete-display': 'Remove stop display {{id}}',
        deleteStop: 'Remove stop {{stop}}',
        deleteView: 'Remove stop display {{id}}',
        departureTime: 'Time/min',
        destination: '',
        displayDirection: 'Direction of the display',
        displayEditorStaticLink: 'Create view',
        displayLanguage: 'Language',
        displayLanguages: 'Languages',
        displayMap: 'Map view',
        duration: 'Duration / language',
        'edit-display': 'Edit display',
        'edit-map': 'Adjust the crop >',
        'front-page-no-sign-in-button': 'Continue without logging in',
        'front-page-sign-in-button': 'Log in and create a display',
        frontPageParagraph1:
          'Create your own display and select the stops and routes you are interested in',
        frontPageParagraph2:
          'You can use the service to generate timetables for nearby stops to be displayed in public spaces, on your information displays or on company intranet. You can also create your own personal stop display and save it as a bookmark in your browser.',
        frontPageParagraph3:
          'Displaying public transport timetables in prominent places encourages the use of public transport and makes it easier. In addition to timetables, the service provides you with service updates and disruption alerts.',
        'front-page-paragraph-hsl':
          'When you log in using your HSL account before creating a display, you can later edit the display.',
        'front-page-paragraph-waltti':
          'When you log in using your Waltti account before creating a display, you can later edit the display.',
        'header-side-left': 'Left column',
        'header-side-right': 'Right column',
        hideAllLines: 'Hide all routes',
        hideLine: 'Hide route {{line}}',
        hideLines: 'Hide routes {{hidden}} / {{all}}',
        'hide-timetable': 'Show only the map view in the presentation',
        horizontal: 'Horizontal',
        import: 'Import',
        'import-instructions':
          'Give the URL address of an existing stop display to add it to your displays.',
        'import-monitor': 'Import display',
        'info-display-only-one':
          'You can select a service update view only for a display with one stop view.',
        'info-text-short': 'Departures in a chronological order',
        'info-text-one-column':
          'Departures in a chronological order in one column',
        'info-text-two-columns':
          'Departures in a chronological order in two columns',
        'info-text-double': 'Display with two views',
        'information-display': 'Service update view',
        'information-display-info':
          'Displays service updates for selected stops and routes',
        languageCode: 'en',
        'language-name-en': 'English',
        'language-name-fi': 'Finnish',
        'language-name-sv': 'Swedish',
        languageSelection: 'Language selection',
        layout: 'Layout',
        'layout-double': 'Double view',
        layoutModalHeader: 'Select layout',
        lineId: 'Rt.',
        'link-copied': 'Link copied to clipboard',
        links: 'Links',
        loading: 'Loading...',
        login: 'Log in',
        logout: 'Log out',
        'map-description':
          'The map view is shown as one view in the presentation. The map view is cropped automatically based on the stops selected for the whole presentation.',
        menuClose: 'Close the main menu',
        menuOpen: 'Open the main menu',
        userMenuClose: 'Close the user menu',
        userMenuOpen: 'Open the user menu',
        modify: 'Edit',
        moveStopToLeftCol: 'Move stop {{stop}} to the left column',
        moveStopToRightCol: 'Move stop {{stop}} to the right column',
        moveViewDown: 'Move stop display {{id}} down',
        moveViewUp: 'Move stop display {{id}} up',
        'new-display-disabled':
          'You cannot add more than one view to the service update view',
        'no-departures': 'No known departures',
        'no-monitor-found': 'No display was found matching the URL given.',
        'incorrect-instance':
          'The URL address you entered does not refer to a stop display in this service area.',
        'save-failed': 'Save failed',
        'no-stops-selected': 'No stops selected',
        noMonitors: 'No stop displays found',
        noAlerts: 'No alerts found',
        'non-logged-in-monitor-info':
          'You are editing a display that has been created without logging in to the service. You can save the display in your displays by clicking on the button Create view.',
        notPossibleToCreate:
          'Unable to create a new view without {{requirements}}',
        notPossibleToPreview:
          'Unable to display the preview without {{requirements}}',
        notPossibleToSave: 'Unable to save the view without {{requirements}}',
        'one-column': 'One column',
        open: 'Open the display',
        ok: 'OK',
        or: 'or',
        'platform-or-stop': 'Platform/Stop',
        prepareDisplay: 'Add new stop view',
        prepareStop: 'Add stop',
        preview: 'Preview',
        previewView: 'Preview',
        quickDisplayCreate: 'Create a stop display',
        renameDestinations: 'Edit destination',
        requirementLanguage: 'a language',
        requirementStop: 'a stop',
        rows: 'rows',
        save: 'Save',
        'search-autosuggest-label':
          'Place, route and stop search. Navigate with arrow keys, press Enter to select',
        'search-autosuggest-len': '{{count}} suggestion found',
        'search-autosuggest-len_plural': '{{count}} suggestions found',
        'search-current-suggestion': 'Current selection: {{selection}}',
        'select-bounds': 'Select the desired crop',
        settingsChanged: 'Settings changed',
        show: 'Show',
        showMap: 'Show in presentation ',
        showEndOfLine: 'Routes terminating or stopping at this stop',
        showRouteColumn: 'Route column',
        showStopNumber: 'Stop or platform number',
        showVia: 'Via information, if available',
        showRouteNameInsteadOfDestination: 'Trip name instead of destination',
        showOnlyArrivals: 'Arrivals only',
        showOnlyDepartures: 'Departures only',
        sideleft: 'Left headline',
        sideright: 'Right headline',
        simple: 'Fixed height',
        'skip-to-main-content': 'Go to the main content of this page',
        staticMonitorTitle: 'Name of the display',
        stop: 'Stop',
        'stop-display': 'Stop display',
        stopSettings: 'Settings for stop {{stop}} ({{code}})',
        stoptitle: 'Name of the stop view',
        tighten: 'Convergent',
        timeShift: 'Filter departures by time',
        timeShiftDescription:
          'You can exclude departures that are too soon to catch from the location of the stop display.',
        timeShiftShow: 'Only show departures departing in more than',
        'to-own-displays': 'Go to my displays',
        'two-columns': 'Two columns',
        userinfo: 'My information',
        vertical: 'Vertical',
        viewEditorName: 'Name of the view',
        'query-error': 'No connection!\nWe are looking into the issue.',
      },
    },
    fi: {
      translation: {
        'add-at-least-one-stop': 'Lisää vähintään yksi pysäkki',
        'add-to-own-displays': 'Lisää omiin näyttöihin',
        all: 'Kaikki',
        arriveTerminal: 'Saapuu / Päätepysäkki',
        autosuggestPlaceHolder: 'Pysäkin nimi tai numero',
        breadCrumbsCreate: 'Luo pysäkkinäyttö',
        breadCrumbsFrontPage: 'Etusivu',
        breadCrumbsHelp: 'Apua',
        breadCrumbsModify: 'Muokkaa pysäkkinäyttöä',
        breadCrumbsOwnMonitors: 'Omat pysäkkinäytöt',
        breadCrumbsSite: 'Reittiopas',
        cancel: 'Peruuta',
        cancelled: 'Peruttu',
        changeLanguage: 'Vaihda kieleen {{language}}',
        chooseOne: 'Valitse vähintään yksi',
        close: 'Sulje',
        closedStop: 'Pysäkki suljettu',
        closedStopWithRange:
          'Pysäkki {{name}} {{code}} suljettu aikavälillä {{startTime}} - {{endTime}}',
        'confirm-choice': 'Vahvista valinta',
        continue: 'Jatka',
        copy: 'Kopioi linkki',
        createViewTitle: 'Uuden näkymän luonti',
        delete: 'Poista',
        'delete-confirmation':
          'Oletko varma, että haluat poistaa näytön "{{monitor}}"?',
        deleteRenamings: 'Tyhjennä tekstimuutokset',
        'delete-display': 'Poista pysäkkinäyttö {{id}}',
        deleteStop: 'Poista pysäkki {{stop}}',
        deleteView: 'Poista pysäkkinäkymä {{id}}',
        departureTime: 'Aika/min',
        destination: 'Määränpää',
        displayDirection: 'Näytön suunta',
        displayEditorStaticLink: 'Luo näkymä',
        displayLanguage: 'Esityskieli',
        displayLanguages: 'Esityskielet',
        displayMap: 'Karttanäkymä',
        duration: 'Kesto / esityskieli',
        'edit-display': 'Muokkaa näyttöä',
        'edit-map': 'Muokkaa rajausta > ',
        'front-page-no-sign-in-button': 'Jatka kirjautumatta',
        'front-page-sign-in-button': 'Kirjaudu ja luo näyttö',
        frontPageParagraph1:
          'Luo itsellesi oma näyttö ja valitse pysäkit ja linjat joiden aikatauluista olet kiinnostunut',
        frontPageParagraph2:
          'Palvelulla voi tuoda esimerkiksi julkisten tilojen auloihin, yritysten infonäytöille tai intranet-verkkoon kyseisen paikan läheisyydessä olevien pysäkkien aikataulut. Voit myös tehdä henkilökohtaisen pysäkkinäytön ja laittaa sen kirjainmerkiksi selaimeesi.',
        frontPageParagraph3:
          'Joukkoliikenteen aikataulut näkyvällä paikalla helpottavat joukkoliikenteen käyttöä ja kannustavat käyttämään joukkoliikennettä. Aikataulujen lisäksi palvelun kautta saat myös ajankohtaiset liikenne- ja häiriötiedotteet.',
        'front-page-paragraph-hsl':
          'Luo näyttö HSL-tunnuksella kirjautuneena niin pääset myöhemmin muokkaamaan luomaasi pysäkkinäyttöä.',
        'front-page-paragraph-waltti':
          'Luo näyttö Waltti-tunnuksella kirjautuneena niin pääset myöhemmin muokkaamaan luomaasi pysäkkinäyttöä.',
        'header-side-left': 'Vasen palsta',
        'header-side-right': 'Oikea palsta',
        hideAllLines: 'Piilota kaikki linjat',
        hideLine: 'Piilota linja {{line}}',
        hideLines: 'Piilota linjoja {{hidden}} / {{all}}',
        'hide-timetable': 'Näytä esityksessä vain karttanäkymä',
        horizontal: 'Vaaka',
        import: 'Tuo',
        'import-instructions':
          'Anna olemassaolevan pysäkkinäytön URL-osoite, niin voit lisätä sen omiin näyttöihisi',
        'import-monitor': 'Tuo näyttö',
        'info-display-only-one':
          'Voit valita tiedotenäytön vain näyttöön, jossa on yksi pysäkkinäkymä.',
        'info-text-short': 'Lähdöt aikajärjestyksessä',
        'info-text-one-column': 'Lähdöt aikajärjestyksessä yhdellä sarakkeella',
        'info-text-two-columns':
          'Lähdöt aikajärjestysessä kahdella sarakkeella',
        'info-text-double': 'Kaksi näkymää yhdellä näytöllä',
        'information-display': 'Tiedotenäyttö',
        'information-display-info':
          'Esittää tiedotteita valituista pysäkeistä ja linjoista',
        languageCode: 'fi',
        'language-name-en': 'Englanti',
        'language-name-fi': 'Suomi',
        'language-name-sv': 'Ruotsi',
        languageSelection: 'Kielen valinta',
        layout: 'Asettelu',
        'layout-double': 'Kaksoisnäkymä',
        layoutModalHeader: 'Valitse asettelutapa',
        lineId: 'Linja',
        'link-copied': 'Linkki kopioitu leikepöydälle',
        links: 'Linkit',
        loading: 'Ladataan…',
        login: 'Kirjaudu sisään',
        logout: 'Kirjaudu ulos',
        'map-description':
          'Karttanäkymä näkyy yhtenä näkymänä esityksessä. Se rajataan automaattisesti koko esitykseen valittujen pysäkkien perusteella',
        maptitle: 'Karttanäkymän nimi',
        menuClose: 'Sulje päävalikko',
        menuOpen: 'Avaa päävalikko',
        userMenuClose: 'Sulje käyttäjävalikko',
        userMenuOpen: 'Avaa käyttäjävalikko',
        modify: 'Muokkaa',
        moveStopToLeftCol: 'Siirrä pysäkki {{stop}} vasempaan palstaan',
        moveStopToRightCol: 'Siirrä pysäkki {{stop}} oikeaan palstaan',
        moveViewDown: 'Siirrä pysäkkinäkymä {{id}} alemmaksi',
        moveViewUp: 'Siirrä pysäkkinäkymä {{id}} ylemmäksi',
        'new-display-disabled':
          'Et voi lisätä useampia näkymiä tiedotenäyttöön',
        'no-departures': 'Ei tiedossa olevia lähtöjä',
        'no-monitor-found':
          'Antamallasi URL-osoitteella ei löytynyt yhtään monitoria.',
        'incorrect-instance':
          'Antamasi URL-osoite ei viittaa tämän palvelualueen pysäkkinäyttöön.',
        'save-failed': 'Tallennus epäonnistui',
        'no-stops-selected': 'Ei valittuja pysäkkejä',
        noMonitors: 'Pysäkkinäyttöä ei löytynyt',
        noAlerts: 'Tiedotteita ei löytynyt',
        'non-logged-in-monitor-info':
          'Olet muokkaamassa näyttöä, joka on luotu ilman kirjautumista. Voit tallentaa näytön omiin näyttöihisi.',
        notPossibleToCreate: 'Ilman {{requirements}} ei voida luoda näkymää',
        notPossibleToPreview:
          'Ilman {{requirements}} ei voida näyttää esikatselua',
        notPossibleToSave: 'Ilman {{requirements}} ei voida tallentaa näkymää',
        'one-column': 'Yksi sarake',
        open: 'Avaa näyttö',
        ok: 'OK',
        or: 'tai',
        'platform-or-stop': 'Lait./Pys.',
        prepareDisplay: 'Lisää uusi pysäkkinäkymä',
        prepareStop: 'Lisää pysäkki',
        preview: 'Esikatselu',
        previewView: 'Esikatsele',
        quickDisplayCreate: 'Luo pysäkkinäyttö',
        renameDestinations: 'Muokkaa määränpää-tekstejä',
        requirementLanguage: 'kieltä',
        requirementStop: 'pysäkkiä',
        rows: 'riviä',
        save: 'Tallenna',
        'search-autosuggest-label':
          'Paikka, linja ja pysäkkihaku. Navigoi listassa nuolinäppäimillä ja valitse enterillä',
        'search-autosuggest-len': 'Löydettiin {{count}} ehdotus',
        'search-autosuggest-len_plural': 'Löydettiin {{count}} ehdotusta',
        'search-current-suggestion': 'Tämänhetkinen valinta: {{selection}}',
        'select-bounds': 'Valitse sopiva rajaus',
        settingsChanged: 'Muutettuja asetuksia',
        show: 'Näytä',
        showMap: 'Näytä esityksessä',
        showEndOfLine: 'Linjat, joille tämä on pääte- tai jättöpysäkki',
        showRouteColumn: 'Linja-sarake',
        showStopNumber: 'Pysäkki- tai laiturinumero',
        showVia: 'Kauttakulkutieto (via), jos saatavilla',
        "showRouteNameInsteadOfDestination": "Matkan nimi määränpään sijaan",
        "showOnlyEndingTrips": "Näytä vain matkat, jotka päättyvät tähän pysäkkiin",
        "excludeEndingTrips": "Sulje pois matkat, jotka päättyvät tälle pysäkille ",
        sideleft: 'Vasen otsikko',
        sideright: 'Oikea otsikko',
        simple: 'Tasavälinen',
        'skip-to-main-content': 'Siirry sivun pääsisältöön',
        staticMonitorTitle: 'Näytön nimi',
        stop: 'Pysäkki',
        'stop-display': 'Pysäkkinäyttö',
        stopSettings: 'Pysäkin {{stop}} {{code}} asetukset',
        stoptitle: 'Pysäkkinäkymän nimi',
        tighten: 'Tiivistyvä',
        timeShift: 'Rajaa lähtöjä ajan mukaan',
        timeShiftDescription:
          'Voit rajata esityksestä lähdöt, joihin pysäkkinäytön sijainnista ei\nole mahdollista ehtiä kyytiin.',
        timeShiftShow: 'Näytä vain lähdöt, joiden lähtöön on yli',
        'to-own-displays': 'Siirry omiin näyttöihin',
        'two-columns': 'Kaksi saraketta',
        userinfo: 'Omat tiedot',
        vertical: 'Pysty',
        viewEditorName: 'Näkymän nimi',
        'query-error': 'Yhteys poikki!\nSelvitämme tilannetta.',
      },
    },
    et: {
      translation: {
        "add-at-least-one-stop": "Lisage vähemalt üks peatus",
        "add-to-own-displays": "Lisa minu kuvadele",
        "all": "Kõik",
        "arriveTerminal": "Saabub / Terminus",
        "autosuggestPlaceHolder": "Peatuse nimi või number",
        "breadCrumbsCreate": "Loo stoppkuva",
        "breadCrumbsFrontPage": "Esileht",
        "breadCrumbsHelp": "Abi",
        "breadCrumbsModify": "Muutke stoppkuva",
        "breadCrumbsOwnMonitors": "Minu peatus kuvatakse",
        "breadCrumbsSite": "Reisi planeerija",
        "cancel": "Tühista",
        "cancelled": "Tühistatud",
        "changeLanguage": "Muuda keeleks {{language}}",
        "chooseOne": "Valige vähemalt üks keel",
        "close": "Sule",
        "closedStop": "Peatus suletud",
        "closedStopWithRange": "Peatus {{name}} {{code}} vahel suletud {{startTime}} ja {{endTime}}",
        "confirm-choice": "Kinnitage valik",
        "continue": "Jätka",
        "copy": "Kopeeri link",
        "createViewTitle": "Uue vaate loomine",
        "delete": "Kustuta",
        "delete-confirmation": "Kas olete kindel, et soovite selle monitori kustutada?",
        "deleteRenamings": "Loobu muudatustest",
        "delete-display": "Eemaldage stoppekraan {{id}}",
        "deleteStop": "Eemalda stopp {{stop}}",
        "deleteView": "Eemaldage stoppekraan {{id}}",
        "departureTime": "Aeg/min",
        "destination": "",
        "displayDirection": "Ekraani paigutus",
        "displayEditorStaticLink": "Loo vaade",
        "displayLanguage": "Keel",
        "displayLanguages": "Keeled",
        "displayMap": "Kaardivaade",
        "duration": "Kestus / keel",
        "edit-display": "Redigeeri kuva",
        "edit-map": "Reguleerige kärpimist >",
        "front-page-no-sign-in-button": "Jätkake sisse logimata",
        "front-page-sign-in-button": "Logige sisse ja looge ekraan",
        "frontPageParagraph1": "Looge oma ekraan ja valige teid huvitavad peatused ja marsruudid",
        "frontPageParagraph2": "Teenuse abil saate koostada lähipeatuste sõiduplaane, mida kuvada avalikus ruumis, oma infoekraanidel või ettevõtte siseveebis. ",
        "frontPageParagraph3": "Ühistranspordi sõiduplaanide väljapanek silmapaistvatel kohtadel soodustab ühistranspordi kasutamist ja muudab selle lihtsamaks. ",
        "front-page-paragraph-hsl": "Kui logite enne kuva loomist oma HSL-i kontoga sisse, saate kuva hiljem redigeerida.",
        "front-page-paragraph-waltti": "Kui logite enne kuva loomist oma Waltti kontoga sisse, saate kuva hiljem redigeerida.",
        "header-side-left": "Vasak veerg",
        "header-side-right": "Parem veerg",
        "hideAllLines": "Peida kõik marsruudid",
        "hideLine": "Peida marsruut {{line}}",
        "hideLines": "Peida marsruudid {{hidden}} / {{all}}",
        "hide-timetable": "Kuva esitlusel ainult kaardivaade",
        "horizontal": "Horisontaalne",
        "import": "Import",
        "import-instructions": "Sisestage olemasoleva peatuse kuva URL-aadress, et see oma kuvadele lisada.",
        "import-monitor": "Impordi ekraan",
        "info-display-only-one": "Teenuse värskenduse vaate saate valida ainult ühe peatuse vaatega kuva jaoks.",
        "info-text-short": "Väljumised kronoloogilises järjekorras",
        "info-text-one-column": "Väljumised kronoloogilises järjekorras ühes veerus",
        "info-text-two-columns": "Väljumised kronoloogilises järjekorras kahes veerus",
        "info-text-double": "Ekraan kahe vaatega",
        "information-display": "Teenuse värskenduse vaade",
        "information-display-info": "Kuvab valitud peatuste ja marsruutide teenusevärskendused",
        "languageCode": "et",
        "language-name-en": "inglise keel",
        "language-name-fi": "soome keel",
        "language-name-sv": "rootsi keel",
        "languageSelection": "Keele valik",
        "layout": "Paigutus",
        "layout-double": "Kahekordne vaade",
        "layoutModalHeader": "Valige paigutus",
        "lineId": "Liin",
        "link-copied": "Link on lõikelauale kopeeritud",
        "links": "Lingid",
        "loading": "Laadimine...",
        "login": "Logi sisse",
        "logout": "Logi välja",
        "map-description": "Kaardivaadet näidatakse esitlusel ühe vaatena. ",
        "menuClose": "Sulgege peamenüü",
        "menuOpen": "Avage peamenüü",
        "userMenuClose": "Sulgege kasutajamenüü",
        "userMenuOpen": "Avage kasutajamenüü",
        "modify": "Muuda",
        "moveStopToLeftCol": "Liikumise peatus {{stop}} vasakpoolsesse veergu",
        "moveStopToRightCol": "Liikumise peatus {{stop}} paremasse veergu",
        "moveViewDown": "Liiguta stoppkuva {{id}} alla",
        "moveViewUp": "Liiguta stoppkuva {{id}} üles",
        "new-display-disabled": "Teenuse värskenduse vaatesse ei saa lisada rohkem kui ühte vaadet",
        "no-departures": "Väljumisi pole teada",
        "no-monitor-found": "Antud URL-ile vastavat kuva ei leitud.",
        "incorrect-instance": "Sisestatud URL-aadress ei viita peatusele selles teeninduspiirkonnas.",
        "save-failed": "Salvestamine ebaõnnestus",
        "no-stops-selected": "Ühtegi peatust pole valitud",
        "noMonitors": "Peatuskuvasid ei leitud",
        "noAlerts": "Märguandeid ei leitud",
        "non-logged-in-monitor-info": "Redigeerite kuva, mis on loodud ilma teenusesse sisse logimata. ",
        "notPossibleToCreate": "Ilma selleta ei saa uut vaadet luua {{requirements}}",
        "notPossibleToPreview": "Ilma eelvaadet ei saa kuvada {{requirements}}",
        "notPossibleToSave": "Ilma selleta ei saa vaadet salvestada {{requirements}}",
        "one-column": "Üks veerg",
        "open": "Avage ekraan",
        "ok": "OK",
        "or": "või",
        "platform-or-stop": "Platvorm/peatus",
        "prepareDisplay": "Lisa uus tabloo vaade ",
        "prepareStop": "Lisa peatus",
        "preview": "Eelvaade",
        "previewView": "Eelvaade",
        "quickDisplayCreate": "Loo stoppkuva",
        "renameDestinations": "Muuda ",
        "requirementLanguage": "keel",
        "requirementStop": "peatus",
        "rows": "read",
        "save": "Salvesta",
        "search-autosuggest-label": "Koha, marsruudi ja peatuse otsing. ",
        "search-autosuggest-len": "{{count}} soovitus leitud",
        "search-autosuggest-len_plural": "{{count}} ettepanekuid leitud",
        "search-current-suggestion": "Praegune valik: {{selection}}",
        "select-bounds": "Valige soovitud saak",
        "settingsChanged": "Seadistused muudetud",
        "show": "Näita",
        "showMap": "Näita esitluses ",
        "showEndOfLine": "Selles peatuses lõppevad või peatuvad marsruudid",
        "showRouteColumn": "Liini number",
        "showStopNumber": "Peatuse või platvormi number",
        "showVia": "Teabe kaudu, kui see on saadaval",
        "showRouteNameInsteadOfDestination": "Reisi nimi sihtkoha nime asemel",
        showOnlyArrivals: 'Ainult saabuvad reisid',
        showOnlyDepartures: 'Ainult väljuvad reisid',
        "sideleft": "Vasakpoolne pealkiri",
        "sideright": "Õige pealkiri",
        "simple": "Fikseeritud kõrgus",
        "skip-to-main-content": "Minge selle lehe põhisisu juurde",
        "staticMonitorTitle": "Kuvari nimi",
        "stop": "Peatus",
        "stop-display": "Peatage kuvamine",
        "stopSettings": "Tabloo seaded {{stop}} ({{code}})",
        "stoptitle": "Tabloo vaate nimi",
        "tighten": "Konvergentne",
        "timeShift": "Filtreerimine",
        "timeShiftDescription": "Filtreerige väljumisi aja järgi",
        "timeShiftShow": "Kuva ainult need väljumised, mis väljuvad rohkem hiljem kui",
        "to-own-displays": "Mine minu kuvade juurde",
        "two-columns": "Kaks veergu",
        "userinfo": "Minu andmed",
        "vertical": "Vertikaalne",
        "viewEditorName": "Tabloo vaate nimi",
        "query-error": "Ühendust pole!\nTegeleme probleemiga."
      }
    }
  },
});

export default i18n;
